import _useScrollPosition from "./useScrollPosition";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var useScrollPosition_1 = _useScrollPosition;
Object.defineProperty(exports, "useScrollPosition", {
  enumerable: true,
  get: function () {
    return useScrollPosition_1.useScrollPosition;
  }
});
export default exports;
export const __esModule = exports.__esModule,
      useScrollPosition = exports.useScrollPosition;